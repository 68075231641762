import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import BelgiumDocValidation from "../BelgimDocValidation/BelgiumDocValidation";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import GeneralDocValidation from "../GeneralDocValidation/GeneralDocValidation";
import GenDocumentPreview from "../GenDocument/GenDocumentPreview";
import GenDocument from "../GenDocument/GenDocument";
import BreadcrumbsComponent from "../BreadCrumb/BreadCrumb";
import HeaderComponent from "../Header/Header";
import InvoicePreview from "../Invoice/InvoicePreview";
import AadharPreview from "../Aadhar/AadharPreview";
import PreviewComponent from "../PreviewComponent/PreviewComponent";
import Invoice from "../Invoice/Invoice";
import CriminalCertificate from "../CriminalCertificate/CriminalCertificate";
import CriminalCertificatePreview from "../CriminalCertificate/CriminalCertificatePreview";
import Pan from "../Pancard/Pan";
import panPreview from "../Pancard/panPreview";
import AadharCard from "../Aadhar/aadharCard";
import Bank from "../Bank/Bank";
import BankPreview from "../Bank/BankPreview";
import Bill from "../EwayBill/EwayBill";
import EwayPreview from "../EwayBill/EwayPreview";
import GST from "../GST/GST";
import GstPreview from "../GST/GstPreview";
import BusinessCard from "../BusinessCard/BusinessCard"
import BusinessCardPreview from "../BusinessCard/BusinessCardPreview";
import IdDocument from "../IdDocument/IdDocument";
import IdDocumentPreview from "../IdDocument/IdDocumentPreview";
import Receipt from "../Receipt/Receipt";
import ReceiptPreview from "../Receipt/ReceiptPreview";
import PassBook from "../BankPassbook/PassBook";
import PassbookPreview from "../BankPassbook/PassBookPreview";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `0`,
    // overflowY: "auto",
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MainComponent({ open }) {
  return (
    <Main open={open}>
      <DrawerHeader />
      <HeaderComponent />
      {/* <BreadcrumbsComponent /> */}
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/my-ai/criminal-document"
          element={<CriminalCertificate />}
        />
        <Route path="/my-ai/criminal-document/preview/:id" Component={CriminalCertificatePreview} />
        <Route path="/doc-ai/invoice" Component={Invoice} />
        <Route path="/doc-ai/pan" Component={Pan} />
        <Route path="/doc-ai/pan/preview/:id" Component={panPreview} />
        <Route path="/doc-ai/bank-cheque" Component={Bank}/>
        <Route path="/doc-ai/bank-cheque/preview/:id" Component={BankPreview} />
        <Route path="/doc-ai/invoice/preview/:id" Component={InvoicePreview} />
        <Route path="/doc-ai/eway-bill" Component={Bill} />
        <Route path="/doc-ai/eway-bill/preview/:id" Component={EwayPreview} />
        <Route path="/doc-ai/receipt" Component={Receipt} />
        <Route path="/doc-ai/receipt/preview/:id" Component={ReceiptPreview} />
        <Route path="/doc-ai/aadhar-card" Component={AadharCard} />
        <Route path="/doc-ai/aadhar-card/preview/:id" Component={AadharPreview} />
        <Route path="/doc-ai/general-document" Component={GenDocument} />
        <Route path="/doc-ai/general-document/preview/:id" Component={GenDocumentPreview} />
        <Route path="/doc-ai/gst-certification" Component={GST} />
        <Route path="/doc-ai/gst-certification/preview/:id" Component={GstPreview} />
        <Route path="/doc-ai/business-card" Component={BusinessCard} />
        <Route path="/doc-ai/business-card/preview/:id" Component={BusinessCardPreview} />
        <Route path="/doc-ai/id-document" Component={IdDocument} />
        <Route path="/doc-ai/id-document/preview/:id" Component={IdDocumentPreview} />
        <Route path="/doc-ai/bank-passbook" Component={PassBook} />
        <Route path="/doc-ai/bank-passbook/preview/:id" Component={PassbookPreview} />



        {/* <Route
          path="/general-document-validation"
          element={<GeneralDocValidation />}
        /> */}
        <Route
          path="/doc-ai/*"
          element={<GeneralDocValidation />}
        />
           <Route
          path="/vision-ai/*"
          element={<GeneralDocValidation />}
        />
      </Routes>
    </Main>
  );
}
