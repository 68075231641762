import React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({ open, handleClose }) => {
  return (
    <Backdrop
      sx={(theme) => ({ color: "#EAECF0", zIndex: theme.zIndex.drawer + 1 })}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color="inherint" />
    </Backdrop>
  );
};
export default Loader;
