// src/Login.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "../Snackbar/Snackbar";
import Logo from "../../assets/main-logo.svg";
import login from "../../assets/Login-img.png";
import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Link,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import apiService from "../../service";
import "./Login.css"; // Import the external CSS file

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [open, setOpen] = React.useState(false);

  const handleSubmit = async (event) => {
    setShowSnackbar(false);
    try {
      event.preventDefault();
      const obj = {
        email,
        password,
        withCredentials: true
      };
      // Handle login logic here
      // For simplicity, we assume login is always successful
      const response = await apiService.login(obj);
      console.log(response);
      if (response.status) {
        setSnackbarMessage(response.message);
        setSnackbarVariant("success");
        setShowSnackbar(true);
        // toast.success(response.message);
        
        localStorage.setItem("userId", response?.data?.user_id);
        localStorage.setItem("firstName", response?.data?.first_name);
        localStorage.setItem("lastName", response?.data?.last_name);
        localStorage.setItem("access", response?.data?.tenant?.access_list);
        // localStorage.setItem("tenant", response?.data?.tenant_id);
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("apiKey", response?.data?.api_key);
        localStorage.setItem("refreshToken", response?.data?.refreshToken);
        navigate("/my-ai/criminal-document");
      } else {
        setSnackbarMessage(response.message);
        setSnackbarVariant("error");
        setShowSnackbar(true);
        // toast.error(response.message);
      }
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarVariant("error");
      setShowSnackbar(true);
      // toast.error(
      //   "Error analyzing document. Please upload a valid certificate in PDF format"
      // );
      // setMessage(
      //   "Error analyzing document. Please upload a valid certificate in PDF format"
      // );
    }
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      navigate("/custom-document-validation"); // Redirect to dashboard if already logged in
    }
  }, [navigate]);

  return (
    <div className="main-container">
      <Box className="imagecontainer">
        <img src={login} alt="Background" />
      </Box>

      <Box className="rightcontainer"
      >
        <Box sx={{ maxWidth: 350, width: "100%" }}>
          <Box className="righttopconatiner" >
            <img className="logo"
              src={Logo}
              alt="Logo"
            />
            <Typography variant="subtitle1">
              Sign In to HaawkAI
            </Typography>
          </Box>
          <form>
            <TextField
              variant="outlined"
              fullWidth
              label="Email Address"
              type="email"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() =>
                      setShowPassword(!showPassword)
                    }
                    edge="end"
                    className="visibility-icon"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                   ),
                  }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Remember Me"
              />
              <Link className="forgot">Forgot Password?</Link>
            </Box>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              onClick={handleSubmit}
            >
              Sign In
            </Button>
            <Typography className="subtitle2" style={{textAlign:'center',fontFamily:'SF Pro Text',fontSize:'15px',marginTop:'20px'}}>
              By clicking Sign In, you agree to our Terms of Service and Privacy Policy
            </Typography>
            <Typography align="center">
              Don&apos;t have an account?{" "}
              <Link  className="singup">Sign Up</Link>
            </Typography>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default Login;
