import React, { useEffect, useState, useRef } from "react";
import CustomBreadCrumbs from "../BreadCrumb/BreadCrumb";
import GeneralDocValidation from "../GeneralDocValidation/GeneralDocValidation";
import "../../CriminalCertificateValidation.css";
import { Box, Tooltip } from "@mui/material";
import Download from "../../assets/Download.svg";
import Plus from "../../assets/plus.svg";
import Analyse from "../../Analyse";
import apiService from "../../service";
import { useNavigate } from "react-router-dom";
import GridDataComponent from "../GridDataComponent/CommonTable";
import View from "../../assets/view.svg";
import Delete from "../../assets/delete.svg";
import ButtonComponent from "../ButtonComponent/Button";
import Chip from "@mui/material/Chip";
import moment from "moment";
import Loader from "../Common/Loader";

const Receipt = () => {

  const access = localStorage.getItem('access');
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    if (access.includes('HawkAI-BankCheque')) {
      setShow(true);
    }
  }, [access]);
  const navigate = useNavigate();
  
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [open, setOpen] = useState(false);

  const breadCrumbArray = [
    {
      name: "receipt",
      color: "#767676",
      underline: "none",
    },
  ];
  const [data, setData] = useState([]);
  const [dataAvailable, setDataAvailable] = useState([]);
  const [showDataGrid, setShowDataGrid] = useState(false);
  const tableHeight = `calc(100vh - 200px)`;
  const [isAnalyseOpen, setIsAnalyseOpen] = useState(false);
  const [showUploadDropdown, setShowUploadDropdown] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [sortModel, setSortModel] = useState([{ field: "uploadedon", sort: "desc" }]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [filteredRows, setFilteredRows] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [currentPageData, setCurrentPageData] = useState([]);

 

  const toggleUploadDropdown = () => {
    setShowUploadDropdown(!showUploadDropdown);
  };
  
  const closeAnalysModal = () => {
    setIsAnalyseOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleFileClick = (id, file_name) => {
        navigate(`/doc-ai/receipt/preview/${id}`);
  };

  const removeDuplicates = (data) => {
    return data.filter((value, index, self) => {
      return index === self.findIndex((t) => t.id === value.id);
    });
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      const totalDataFetched = paginationModel.page * paginationModel.pageSize;
      const currentRangeEnd = Math.ceil(totalDataFetched / 100) * 100;
      const requiredPage = Math.ceil(currentRangeEnd / 100);

      if (hasLoaded && currentRangeEnd === 0) {
        return;
      }

      if (totalDataFetched % 100 === 0) {
        setHasLoaded(true);
        const newPageData = await fetchData(requiredPage);

        if (Array.isArray(newPageData) && newPageData.length > 0) {
          const updatedData = removeDuplicates([
            ...dataAvailable,
            ...newPageData,
          ]);
          setDataAvailable(updatedData);
        } else {
          console.error("No new data available from API!");
        }
      }
      const startIndex = (paginationModel.page - 1) * paginationModel.pageSize;
      const endIndex = Math.min(
        startIndex + paginationModel.pageSize,
        dataAvailable.length
      );
      setCurrentPageData(dataAvailable.slice(startIndex, endIndex));
    };

    fetchDataAsync();
  }, [paginationModel, hasLoaded]);

  const handlePaginationModelChange = (newModel) => {
    const { page, pageSize } = newModel;
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;
    setFilteredRows(rows.slice(startIndex, endIndex));
    setPaginationModel(newModel);
  };
  const fetchData = async (currentPage) => {
    setOpen(true);
    try {
      const response = await apiService.getCommonLimits(
        currentPage + 1,
        "receipts",
        100
      );

      if (response && Array.isArray(response.documents)) {
        setOpen(false);
        setRowCount(response.total_docs || 0);
        setShowDataGrid(true);
        return response.documents;
      } else {
        console.error("Invalid response format:", response);
        setOpen(false);
        setShowDataGrid(false);
        return [];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setOpen(false);
      setShowDataGrid(false);
      return [];
    }
  };

  useEffect(() => {
    const rows = dataAvailable && dataAvailable.map((row) => {
        const createdAt = moment(row.created_at);
        return {
          id: row.id,
          fileName: row.file_name,
          uploadedon: createdAt.isValid()
            ? createdAt.format("DD MMM YYYY")
            : "",
          extractedFields: row.extracted_data ? renderExtractedFields(row) : "",
        };
      });
    setRows(rows);
  }, [dataAvailable, sortModel]);

  const handleSortModelChange = async (newSortModel) => {
    setSortModel(newSortModel);
    const sortField = newSortModel[0]?.field || "created_at";
    const sortOrder = newSortModel[0]?.sort || "desc";

    try {
      const response = await apiService.getCommonSortAPI(
        sortField,
        sortOrder,
        "receipts",
        1,
        100
      );
      const data = response;
      if (response && Array.isArray(data)) {
        const sortedRows = data.map((row) => {
          const createdAt = moment(row.created_at);
          return {
            id: row.id,
            fileName: row.file_name,
            uploadedon: createdAt.isValid()? createdAt.format("DD MMM YYYY"): "",
            extractedFields: row.extracted_data? renderExtractedFields(row): "",
          };
        });

        setRows(sortedRows);
      } else {
        console.error("Error fetching sorted data:", data);
      }
    } catch (error) {
      console.error("API call failed:", error);
    }
  };

  const renderExtractedFields = (row) => {
    const extractedData =
      dataAvailable.find((data) => data.id === row.id)?.extracted_data || {};
    const extractedValuesArray = Object.values(extractedData)
      .filter((field) => field?.Content)
      .map((field) => field.Content);
    const maxChips = 3;
    const displayedFields = extractedValuesArray.slice(0, maxChips);
    const hiddenFields = extractedValuesArray.slice(maxChips);
    const hiddenFieldsCount =
      extractedValuesArray.length - displayedFields.length;
    const chipStyles = [
      { backgroundColor: "#EAECF0", color: "black" },
      { backgroundColor: "#F7E8EF", color: "#6A1039" },
      { backgroundColor: "#E8EEF7", color: "#6C86AF" },
    ];

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {displayedFields.map((field, index) => (
          <Chip
            key={field}
            label={field}
            variant="outlined"
            size="small"
            style={{
              margin: "2px",
              ...chipStyles[index % chipStyles.length],
              border: 0,
              width: "130px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          />
        ))}
        {hiddenFieldsCount > 0 && (
          <Tooltip
            title={
              <Box
                sx={{
                  display: "flex",
                  background: "white",
                  flexWrap: "wrap",
                  gap: "4px",
                  p: 1, // Padding to create some space around the chips
                }}
              >
                {hiddenFields.map((field, index) => (
                  <Chip
                    key={field}
                    label={field}
                    variant="outlined"
                    size="small"
                    style={{ margin: "2px", border: 0 }}
                  />
                ))}
              </Box>
            }
            arrow
            placement="top"
          >
            <Chip
              key="more"
              label={`+${hiddenFieldsCount}`}
              variant="outlined"
              size="small"
              style={{
                margin: "2px",
                backgroundColor: "#F2F4F7",
                color: "#344054",
              }}
            />
          </Tooltip>
        )}
      </div>
    );
  };
  const openAnalyseModal = () => {
    setIsAnalyseOpen(true);
  };
  const addTooltipToCell = (params) => (
    <Tooltip
      title={params.value ? params.value.toString() : ""}
      arrow
      placement="left"
    >
      <span
        style={{ cursor: "pointer" }}
        className="file-name"
            onClick={() => handleFileClick(params.row.id, params.row.file_name)}
      >
        {params.value}
      </span>
    </Tooltip>
  );
  const renderActions = (params) => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
                  
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <img
          style={{ cursor: "pointer" }}
          src={View}
          onClick={() => handleFileClick(params.row.id, params.row.file_name)}
          alt="View"
          height={20}
        />
        <img
          style={{ cursor: "not-allowed" }}
          src={Delete}
          alt="Delete"
          height={20}
        />
      </div>
    );
  };

  const columns = [
    {
      field: "fileName",
      headerName: "File Name",
      flex: 2,
      headerClassName: "header-cell",
      headerAlign: "left",
      renderCell: (params) => addTooltipToCell(params),
    },
    {
      field: "uploadedon",
      headerName: "Uploaded on",
      flex: 2,
      headerClassName: "header-cell",
      headerAlign: "left",
      // renderCell: renderDate,
    },
    {
      field: "extracted",
      headerName: "Fields Detected",
      flex: 5,
      headerClassName: "app-theme-header",
      renderCell: (params) => renderExtractedFields(params.row),
      
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "header-cell",
      headerAlign: "left",
      renderCell: renderActions,
    },
  ];

  const openModalFunction = () => {
    setIsAnalyseOpen(true);
  };

  return (
    <>
      {open ? (
        <Loader open={open} handleClose={handleClose} />
      ) : (
        <>
          {show ? (
            <div className="page-container">
              <CustomBreadCrumbs array={breadCrumbArray} />
              <div className="ocr-form-container App">
                {dataAvailable && showDataGrid && (
                  <div className="view-all-container">
                    <div className="button-container-modal">
                      <div className="button-container-end">
                        <ButtonComponent
                          onClick={() => console.log("Download Excel")}
                          className="excel-download"
                          icon={Download}
                          iconAlt="Download"
                          iconHeight={15}
                          text="Export"
                        />
                        <ButtonComponent
                          onClick={openAnalyseModal}
                          buttonRef={buttonRef}
                          className="analyse-upload"
                          icon={Plus}
                          iconAlt="Upload"
                          iconHeight={15}
                          text="Upload"
                        />
                      </div>
                    </div>

                    {showUploadDropdown && (
                      <div className="upload-dropdown" ref={dropdownRef}>
                        <h3>Drag and Drop to upload files</h3>
                        <p>PDF files not more than 5 MB</p>
                        <div className="upload-actions">
                          <button className="select-file-button">Select File</button>
                          <button
                            className="cancel-button"
                            onClick={toggleUploadDropdown}
                          >
                            Cancel
                          </button>
                          <button className="analyze-button">Analyze</button>
                        </div>
                      </div>
                    )}
                    <Box
                      sx={{
                        height: tableHeight,
                        width: "100%",
                        "& .app-theme-header": {
                          backgroundColor: "#EAECF0",
                          color: "black",
                        },
                      }}
                    >
                      <GridDataComponent
                        rows={rows}
                        columns={columns}
                        rowCount={rowCount}
                        paginationMode='client'
                        className="datagrid-class"
                        checkboxSelection={false}
                        onSortModelChange={handleSortModelChange}
                        localeText={{
                          MuiTablePagination: {
                            labelRowsPerPage: "Rows per page",
                          },
                        }}
                        pageSizeOptions={[25, 50, 100]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                      />
                    </Box>
                  </div>
                )}
              </div>
          <Analyse isOpen={isAnalyseOpen} onClose={closeAnalysModal} from="receipt" />
            </div>
          ) : (
            <GeneralDocValidation />
          )}
        </>
      )}
    </>
  );
};

export default Receipt;
