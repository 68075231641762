// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./components/Fonts/PublicSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./components/Fonts/SFPRODISPLAYREGULAR.OTF", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  display: flex !important;
}

.css-1wuqoxd {
  padding: 0px 20px !important;
}
.css-y3y00b {
  padding: 0px 20px !important;
}

@media (min-width: 600px) {
  .css-1k455el {
      min-height: 0 !important;
  }
}
 
/* Custom Font */
@font-face {
  font-family: 'Public Sans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}
 `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,4BAA4B;AAC9B;AACA;EACE,4BAA4B;AAC9B;;AAEA;EACE;MACI,wBAAwB;EAC5B;AACF;;AAIA,gBAAgB;AAChB;EACE,0BAA0B;EAC1B,+DAAkF;EAClF,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,0BAA0B;EAC1B,+DAAyE;EACzE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".app {\n  display: flex !important;\n}\n\n.css-1wuqoxd {\n  padding: 0px 20px !important;\n}\n.css-y3y00b {\n  padding: 0px 20px !important;\n}\n\n@media (min-width: 600px) {\n  .css-1k455el {\n      min-height: 0 !important;\n  }\n}\n\n@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;700&display=swap');\n \n/* Custom Font */\n@font-face {\n  font-family: 'Public Sans';\n  src: url('./components/Fonts/PublicSans-VariableFont_wght.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n@font-face {\n  font-family: 'SF Pro Text';\n  src: url('./components/Fonts/SFPRODISPLAYREGULAR.OTF') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
